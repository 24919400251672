<!-- 首页 -->
<template>
  <div class="binding_list_box">
    <div>
      <div class="search-box">
        <van-search
          class="search"
          v-model="value"
          shape="round"
          @search="get_tab_list(true)"
          @clear="get_tab_list(true)"
          placeholder="请输入地址或设备编号"
        />
      </div>

      <div class="home-tab-list" v-if="list_show">
        <load-more
          :pageIndex="pageIndex"
          :pageSize="pageSize"
          :totalCount="totalCount"
          @loadmore="loadmore"
        >
          <div
            class="home-tab-item"
            v-for="(item, index) in tab_list"
            :key="index"
          >
            <h3>{{ item.location }}</h3>
            <p class="addr">
              <i class="iconfont icon-dingwei-1"></i>
              {{ item.address_detail || "无" }}
            </p>

            <div class="btm_info">
              <p class="num">编号：{{ item.id }}</p>
              <p class="date">{{ item.created_at || "无" }}</p>
            </div>

            <div class="updata_btn" @click="to_detail(item)">更新</div>
          </div>
        </load-more>
      </div>

      <div class="no-list" v-else>
        <img
          class="no-img"
          src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/res/images/202106/25/chongdian/2021629-101722.png"
        />
        <p class="no-txt">暂无绑定设备~</p>
      </div>
    </div>
  </div>
</template>

<script>
import { getData } from "@/api/user";
import { _debounce, getUrlParam } from "@/libs/util";
import wx from "weixin-js-sdk";

export default {
  data() {
    return {
      ismap: false,
      value: "",
      tab_list: [],
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0,
      list_show: true,
    };
  },
  created() {
    this.get_tab_list();
  },
  methods: {
    // 获取列表
    get_tab_list(page = false) {
      if (page) {
        this.pageIndex = 1;
      }
      getData({
        url: "api/maintain/index/qrcode_list",
        data: {
          address: this.value,
        },
        is_admin: true,
      }).then((res) => {
        console.log(res);
        if (res.data.code === 200) {
          let data = res.data.data.data;
          this.pageSize = data.per_page;
          this.totalCount = data.total;
          let list = data.data;
          if (this.pageIndex == 1) {
            this.tab_list = list;
            this.list_show = list.length <= 0 ? false : true;
          } else {
            this.tab_list = this.tab_list.concat(list);
          }
        }
      });
    },
    loadmore(pageIndex) {
      this.pageIndex = pageIndex;
      this.get_tab_list();
    },
    // 更新
    to_detail: _debounce(function (item) {
      console.log(item);
      let that = this;

      getData({
        url: "api/get_js_config",
        data: {
          url: window.location.href.split("#")[0],
        },
      }).then((res) => {
        let data = res.data.data;
        wx.config({
          debug: false,
          appId: data.appId,
          timestamp: data.timestamp,
          nonceStr: data.nonceStr,
          signature: data.signature,
          jsApiList: ["checkJsApi", "scanQRCode"],
        });
        wx.error(function (res) {
          alert("出错了：" + res.errMsg); //这个地方的好处就是wx.config配置错误，会弹出窗口哪里错误，然后根据微信文档查询即可。
        });

        wx.ready(function () {
          wx.checkJsApi({
            // 判断当前客户端版本是否支持指定JS接口
            jsApiList: ["scanQRCode"],
            success: function (res) {
              if (res.checkResult.scanQRCode === true) {
                wx.scanQRCode({
                  desc: "scanQRCode desc",
                  needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                  scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
                  success: function (res) {
                    console.log(res);
                    let result = res.resultStr.split(",") + "";
                    let url = result.split("?")[1] + "";

                    let id = getUrlParam(url, "id");

                    getData({
                      url: "api/charging/check_qr_code",
                      data: {
                        id,
                      },
                    }).then((res) => {
                      console.log(res);
                      if (res.data.code === 200) {
                        that.$router.push({
                          name: "admin_binding_detail",
                          query: {
                            charger_id: item.id,
                            qr_id: id,
                          },
                        });
                      } else if (res.data.code === 422) {
                        that.$toast(res.data.data.msg);
                      }
                    });

                    // this.$router.push({ name: "admin_equipment_msg" });
                  },
                  error: function (err) {
                    alert("扫描失败::扫描码=" + err);
                  },
                });
              } else {
                alert("抱歉，当前客户端版本不支持扫一扫");
              }
            },
            fail: function (res) {
              // 检测getNetworkType该功能失败时处理
              alert("检测getNetworkType该功能失败" + res);
            },
          });
        });
        /* 处理失败验证 */
        wx.error(function (res) {
          // config 信息验证失败会执行error函数,如签名过期导致验证失败,具体错误信息可以打开config的debug模式查看,也可以在返回的res参数中查看,对于SPA可以在这里更新签名
          alert("配置验证失败: " + res.errMsg);
        });
      });
    }),
  },

  beforeRouteEnter(to, from, next) {
    var u = navigator.userAgent;

    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端

    // XXX: 修复iOS版微信HTML5 History兼容性问题

    if (isiOS && to.path !== location.pathname) {
      // 此处不可使用location.replace

      location.assign(to.fullPath);
    } else {
      next();
    }
  },
};
</script>

<style lang="less" scoped>
.binding_list_box {
  .search-box {
    .search {
      background: #f7f7f7;

      .van-search__content {
        background-color: #fff;
      }
    }
  }

  .home-tab-list {
    padding: 0 12px 20px;
  }

  .home-tab-item {
    position: relative;
    margin-bottom: 16px;
    padding: 16px;
    background: #fff;
    border-radius: 5px;
    font-size: 14px;

    h3 {
      margin-bottom: 12px;
      padding-right: 70px;
      font-size: 16px;
      font-weight: 600;
      line-height: 16px;
      color: #333;
    }

    .addr {
      display: flex;
      margin-bottom: 15px;
      padding-right: 70px;
      font-size: 14px;
      line-height: 20px;
      color: #999;

      .iconfont {
        margin-right: 5px;
        font-size: 11px;
        color: #3dc48d;
        flex-shrink: 0;
      }
    }

    .btm_info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      line-height: 1;

      .num {
        font-size: 14px;
        color: #666;
      }

      .date {
        font-size: 14px;
        color: #999;
      }
    }

    .updata_btn {
      position: absolute;
      top: 12px;
      right: 11px;
      width: 59px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: #01bc84;
      text-align: center;
      border: 1px solid #01bc84;
      border-radius: 15px;
    }
  }
}
</style>